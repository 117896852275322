import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { calculationTotalPriceNewOrder, changeChangeCashNewOrder, changeClientInformation, changeDeliveryMethodNewOrder, changePaymentNewOrder, changePointNewOrder, setZonesNewOrder } from "../../action/neworder";
import request from "../../action/utils";
import { urls } from "../../constants/app";
function DetailNewOrder() {
    const filials = useSelector(state => state.handbook.filials);
    const loadingFilials = useSelector(state => state.loaders.filials);
    const loadingFilialsNewOrder = useSelector(state => state.loaders.loadingFilialsNewOrder);
    const point = useSelector(state => state.neworders.point);    
    const deliveryMethod = useSelector(state => state.neworders.deliveryMethod);
    const payment = useSelector(state => state.neworders.payment);
    const changeCash = useSelector(state => state.neworders.changeCash);
    const delivertPrice = useSelector(state => state.neworders.delivertPrice)

    const dispatch = useDispatch();

    const [inputChangeCash, setInputChangeCash] = useState('')
    
    const selectClientData = (state) => {
        return {
            phone: state.neworders.phone,
            area: state.neworders.area,
            street: state.neworders.street,
            house: state.neworders.house,
            entrance: state.neworders.entrance,
            floor: state.neworders.floor,
            room: state.neworders.room,
            comment: state.neworders.comment,
        };
      }; 

    const ClientData = useSelector(selectClientData);

    useEffect(() => {
        setInputChangeCash('')
    }, [changeCash])

    const handleChangePoint = (id) => {
        dispatch(changePointNewOrder(null));

        if(delivertPrice > 0)
            dispatch(calculationTotalPriceNewOrder());
        dispatch(setZonesNewOrder(null));

        dispatch({ type: 'SET_LOADER', field: 'loadingFilialsNewOrder', value: true });
        return request({ method: 'get', url: `${urls.filials}/${id}` })
        .then((response) => {
            fetchZones(id);
            dispatch({ type: 'SET_LOADER', field: 'loadingFilialsNewOrder', value: false });
            dispatch(changePointNewOrder(response));
            return response;
        })
        .catch(error => {
            dispatch({ type: 'SET_LOADER', field: 'loadingFilialsNewOrder', value: false });
            return null
        });
    }

    const fetchZones = (id) => {
        return request({ method: 'get', url: `${urls.zone}`, params: { 'point.id': id } })
            .then((response) => {
                dispatch(setZonesNewOrder(response));
                return response;
            })
            .catch(error => {
                dispatch(setZonesNewOrder(null));
                return null
            });
    }    

    const handleInputChangeCash = (e) => {
        if(e.target.value <= 999999 && e.target.value > 0)
            return setInputChangeCash(parseFloat(e.target.value).toFixed(0))
        if(e.target.value > 999999)
            return setInputChangeCash(999999)
        if(e.target.value <= 0)
            return setInputChangeCash('')
    };

    const renderAdress = (item) => {
        return `${item.city.name}, ${item.street}, ${item.house}`;
    };

    const pointValue = point && {
        value: point.id,
        label: renderAdress(point)
    };

    const renderSelect = () => {
        return(
            <div style={{ width: 300 }}>
                <Select
                    isLoading={loadingFilials || loadingFilialsNewOrder}
                    isDisabled={loadingFilials || loadingFilialsNewOrder}
                    closeMenuOnSelect={true}                    
                    options={filials.map(item => ({
                        value: item.id,
                        label: renderAdress(item)
                    }))}
                    onChange={((e) => handleChangePoint(e.value))}
                    placeholder="Филиал"
                    noOptionsMessage={() => 'Нет филиалов'}
                    value={pointValue}                    
                />
            </div>   
        )
    }
    const renderDeliveryMethodBlock = () => {
        return(
            <>
                <div className="neworder_detail_information">
                    <span>Ожидание:</span>  
                    {point.activate === 'disabledAll' 
                    ?
                    <span>СТОП</span>
                    :
                    <span>{`${point.activate === 'disabledDelivery' ? 'СТОП' : point.minDeliveryTime}/${point.activate === 'disabledOwn' ? 'СТОП' : point.minSamTime}`}</span>
                    }
                </div>
                <div className="neworder_detail_information">
                    <span>Тип заказа:</span>  
                    <div style={{display: 'flex'}}>
                        <button
                        className={`neworder_detail_deliverymethod_button delivery ${deliveryMethod === 'delivery' ? 'actived' : ''}`}
                        onClick={(() => {
                            if(deliveryMethod !== 'delivery')
                                dispatch(changeDeliveryMethodNewOrder('delivery'));
                        })}
                        >
                            Доставка
                        </button>
                        <button
                        className={`neworder_detail_deliverymethod_button own ${deliveryMethod === 'own' ? 'actived' : ''}`}
                        onClick={(() => {
                            if(deliveryMethod !== 'own')
                                dispatch(changeDeliveryMethodNewOrder('own'));
                        })}
                        >
                            Вынос
                        </button>                        
                    </div>
                </div>
            </>
        );
    }
    const renderInputs = () => {
            return(
                <div className="neworder_detail_block">
                    <div className="neworder_detail_input_div">
                        <span className="neworder_detail_input_text">
                            Тел.
                        </span>
                        <input 
                            type="number"
                            className="form-control neworder"
                            autocomplete="false"
                            placeholder="Номер телефона"
                            value={ClientData.phone ? ClientData.phone : ''}
                            onChange={((e) => dispatch(changeClientInformation('phone', parseInt(e.target.value))))}
                            onBlur={((e) => {
                                if(String(parseInt(e.target.value)).length < 11)
                                    dispatch(changeClientInformation('phone', null));
                            })}
                        />
                    </div>
                    {deliveryMethod === 'delivery' &&
                        <>
                            <div className="neworder_detail_input_div">
                                <span className="neworder_detail_input_text">
                                    Ул.
                                </span>
                                <input 
                                    type="text"
                                    className="form-control neworder"
                                    autocomplete="false"
                                    placeholder="Улица"
                                    value={ClientData.street ? ClientData.street : ''}
                                    onChange={((e) => dispatch(changeClientInformation('street', e.target.value)))}
                                />
                            </div>
                            <div className="neworder_detail_input_div">
                                <span className="neworder_detail_input_text">
                                    Д.
                                </span>
                                <input 
                                    type="text"
                                    className="form-control neworder"
                                    autocomplete="false"
                                    placeholder="Дом"
                                    value={ClientData.house ? ClientData.house : ''}
                                    onChange={((e) => dispatch(changeClientInformation('house', e.target.value)))}
                                />
                            </div>
                            <div className="neworder_detail_input_div">
                                <span className="neworder_detail_input_text">
                                    Под.
                                </span>
                                <input 
                                    type="text"
                                    className="form-control neworder"
                                    autocomplete="false"
                                    placeholder="Подъезд"
                                    value={ClientData.entrance ? ClientData.entrance : ''}
                                    onChange={((e) => dispatch(changeClientInformation('entrance', e.target.value)))}
                                />
                            </div>
                            <div className="neworder_detail_input_div">
                                <span className="neworder_detail_input_text">
                                    Эт.
                                </span>
                                <input 
                                    type="text"
                                    className="form-control neworder"
                                    autocomplete="false"
                                    placeholder="Этаж"
                                    value={ClientData.floor ? ClientData.floor : ''}
                                    onChange={((e) => dispatch(changeClientInformation('floor', e.target.value)))}
                                />
                            </div>
                            <div className="neworder_detail_input_div">
                                <span className="neworder_detail_input_text">
                                    Кв.
                                </span>
                                <input 
                                    type="text"
                                    className="form-control neworder"
                                    autocomplete="false"
                                    placeholder="Кв"
                                    value={ClientData.room ? ClientData.room : ''}
                                    onChange={((e) => dispatch(changeClientInformation('room', e.target.value)))}
                                />
                            </div>
                        </>
                    }
                    <div className="neworder_detail_input_div">
                        <textarea 
                            type="text"
                            className="form-control"
                            style={{minHeight: '80px', maxHeight: '80px'}}
                            maxLength={265}
                            autocomplete="false"
                            placeholder="Комментарий"
                            value={ClientData.comment ? ClientData.comment : ''}
                            onChange={((e) => dispatch(changeClientInformation('comment', e.target.value)))}
                        />
                    </div>
                </div>
            );
    }
    const renderPaymentMethod = () => {
        return(
            <div className="neworder_detail_block">
                <button
                className={`order_position_list_footer_button payment ${payment === 'terminal' ? 'actived' : ''}`}
                onClick={(() => dispatch(changePaymentNewOrder('terminal')))}
                >
                    Терминал
                </button>
                {(payment !== 'cash' || changeCash) &&
                <button
                className={`order_position_list_footer_button payment ${payment === 'cash' ? 'actived' : ''}`}
                onClick={(() => dispatch(changePaymentNewOrder('cash')))}
                >                    
                    {
                    changeCash === 'no' ? 'Наличные без сдачи' 
                    :
                    changeCash === 'c1000' ? 'Наличные сдача с 2000 ₸' 
                    :
                    changeCash === 'c1500' ? 'Наличные сдача с 5000 ₸' 
                    :
                    changeCash === 'c2000' ? 'Наличные сдача с 10000 ₸'
                    :
                    changeCash === 'c5000' ? 'Наличные сдача с 20000 ₸'
                    :                
                    `Наличные ${changeCash ? `сдача с ${changeCash.slice(1)} ₸` : ''}`
                    }
                </button>
                }
                {payment === 'cash' && !changeCash &&
                <div className="detail_order_changecash_container">
                    <div className="detail_order_changecash_buttons_div">
                        <button
                        className={`order_position_list_footer_button payment${changeCash === 'no' ? 'actived' : ''}`}
                        onClick={(() => dispatch(changeChangeCashNewOrder('no')))}
                        >
                            Без сдачи
                        </button>
                        <button
                        className={`order_position_list_footer_button payment${changeCash === 'c1000' ? 'actived' : ''}`}
                        onClick={(() => dispatch(changeChangeCashNewOrder('c1000')))}
                        >
                            Сдача с 2000 ₸
                        </button>
                        <button
                        className={`order_position_list_footer_button payment${changeCash === 'c1500' ? 'actived' : ''}`}
                        onClick={(() => dispatch(changeChangeCashNewOrder('c1500')))}
                        >
                            Сдача с 5000 ₸
                        </button>
                    </div>
                    <div className="detail_order_changecash_buttons_div">
                        <button
                        className={`order_position_list_footer_button payment${changeCash === 'c2000' ? 'actived' : ''}`}
                        onClick={(() => dispatch(changeChangeCashNewOrder('c2000')))}
                        >
                            Сдача с 10000 ₸
                        </button>
                        <button
                        className={`order_position_list_footer_button payment${changeCash === 'c5000' ? 'actived' : ''}`}
                        onClick={(() => dispatch(changeChangeCashNewOrder('c5000')))}
                        >
                            Сдача с 20000 ₸
                        </button>
                    </div>
                    <div style={{ display: 'flex', gap: '5px', width: '100%'}}>
                        <input 
                        className="new_order_input"
                        style={{ width: '100%'}}
                        type="number"
                        placeholder="Другая сумма"
                        value={inputChangeCash}
                        onChange={handleInputChangeCash}
                        />
                        <button 
                        className="order_position_list_footer_button"
                        style={{ padding: '0px', height: '1.6rem', minWidth: '2rem'}}
                        onClick={(() => {
                            if(inputChangeCash)                            
                                dispatch(changeChangeCashNewOrder('c' + inputChangeCash));                            
                        })}
                        >
                            <i className="flaticon2-check-mark"/>
                        </button>
                    </div>
                </div>
                }
            </div>
        )
    }
    return(
        <div className="neworder_detail_body">
            {renderSelect()}
            {point && !loadingFilialsNewOrder && renderDeliveryMethodBlock()}
            {point && !loadingFilialsNewOrder && renderInputs()}
            {point && !loadingFilialsNewOrder && renderPaymentMethod()}
        </div>
    );
}
export default DetailNewOrder;