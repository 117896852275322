import React, { Component } from 'react';
import ActionMenu from '../utils/ActionMenu';
import { withRouter  } from 'react-router-dom';
class TablePayments extends Component {    
    handleDoubleClick = (item) => () => {
        if(this.props.role === 4 )
            this.props.history.push(`/handbook/payments/edit/${+item.id}`)
    }
    
    renderBody() {        
        return (
            <tbody className="kt-datatable__body">
                {
                    this.props.items.map(item => {
                        const actions = [
                            { access: 4, name: 'Редактирование', icon: 'flaticon2-contract', action: () => this.props.history.push(`/handbook/payments/edit/${+item.id}`) },
                            { access: 4, name: 'Удалить', icon: 'flaticon2-trash', action: () => this.props.deleteItem(item)}
                        ].filter(item => !item.access || item.access === this.props.role);
                        return (
                            <tr onDoubleClick={this.handleDoubleClick(item)} key={item.id} className="kt-datatable__row">
                            
                            <td className="kt-datatable__cell" style={{ width: '300px' }}>
                                <span style={{ width: '300px' }}>
                                    {item.VisibleName}
                                </span>
                            </td>
                            <td className="kt-datatable__cell" style={{ width: '300px' }}>
                                <span style={{ width: '300px' }}>
                                    {item.Name}
                                </span>
                            </td>
                            <td className="kt-datatable__cell" style={{ width: '95%' }}>
                                <span style={{ width: '300px' }}>
                                    {item.VisibleClient ? 'Виден клиентам' : 'Скрыт для клиентов'}
                                </span>
                            </td>
                            <td className="kt-datatable__cell">
                                <ActionMenu actions={actions}/>
                            </td>
                        </tr>
                        )
                    }) 
                }
            </tbody>
        );
    }
    render() {
        if (this.props.items.length === 0) {
            return (
                <div>Способов оплаты не найдено</div>
            );
        }
        return (
            <table className="kt-datatable__table" style={{ minHeight: '500px' }}>
                {this.renderBody()}
            </table>
        );
    }
}
export default withRouter(TablePayments);