import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGoods, fetchCategories, setActiveCategory } from '../../action/goods';
import ReactTooltip from "react-tooltip";
import { fromStore } from "../../selectors";
import { isEmpty } from "lodash";
import Loader from "../utils/Loader";
import { styles } from "../../containers/goods/styles";
import '../../styles/neworder.css'
import { fetchSettings } from "../../action/app";
import Categories from "./Categories";
import Goods from "./Goods";
import DetailNewOrder from "./DetailNewOrder";
import { fetchFilials } from "../../action/handbook";
import OrderPositionList from "./OrderPositionList";
import { addPositionNewOrder, changeIsAggPriceNewOrder, clearNewOrder } from "../../action/neworder";

function NewOrder() {
    const dispatch = useDispatch();
    const loadingGoods = useSelector(fromStore.loaderGoodsSelector);
    const settings = useSelector(state => state.settings.init.categories);
    const goodsChek = useSelector(state => state.good.list);
    const loadingCategories = useSelector(fromStore.loaderCategoriesSelector);
    const categories = useSelector(fromStore.categoryListSelector);
    const activeCategory = useSelector(fromStore.activeCategorySelector);    
    const loadingFilials = useSelector(state => state.loaders.filials);
    const isAggPrice = useSelector(state => state.neworders.isAggPrice)
    const point = useSelector(state => state.neworders.point)
    const loadingSaveOrder = useSelector(state => state.loaders.loadingSaveOrder);
    
    const [goods, setGoods] = useState([])
    const [searchGoods, setSearchGoods]  = useState(null)
    const [searchingGoods, setSearchingGoods]  = useState(null)
    const onSelectCategory = (id) => () => {        
        dispatch(setActiveCategory(id));            
    };
    const changeModification = (id, value) => {
        const updatedGoods = goods.map(item =>
            item.id === id ? { ...item, isMod: value } : item
        );
        setGoods(updatedGoods);
    }
    const filterGoods = useCallback(() => {
        if(settings && activeCategory !== settings.wok && activeCategory !== settings.pizza)
            if(!point)
            {
                setGoods(goodsChek.filter((item) => item.category.id === activeCategory)
                        .sort((a, b) => {
                            if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                            if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                            return 0;
                        })
                );
            }
            else
            {
                setGoods(goodsChek.filter((item) => item.category.id === activeCategory)
                        .sort((a, b) => {
                            if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                            if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                            return 0;
                        })
                        .filter((item) => { //проверка на доступность позиции в городе
                            const disabledCityIds = item.disabledCities ? item.disabledCities.split(',') : [];
                            return !disabledCityIds.some((id) => parseInt(id) === point.city.id);
                        })
                        .map((item) => //проверка региональных цен
                            item.regionprices.find((regionprices) => 
                                regionprices.cities.some((city) => parseInt(city.id) === point.city.id))
                            ?
                            { ...item, 
                                price: item.price + item.regionprices.find((regionprices) => 
                                    regionprices.cities.some((city) => parseInt(city.id) === point.city.id)).price, 
                                oldPrice: item.oldPrice + item.regionprices.find((regionprices) => 
                                    regionprices.cities.some((city) => parseInt(city.id) === point.city.id)).oldPrice
                            } 
                            : 
                            item                   
                        )
                );                
            }

        if(settings && activeCategory === settings.wok)            
            if(!point)
            {
                setGoods(goodsChek.filter((item) => item.category.id === activeCategory || item.category.id === settings.mainWok)
                        .sort((a, b) => {
                            if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                            if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                            return 0;
                        })
                            .sort((a, b) => b.category.id - a.category.id)
                            .map((item) => 
                                item.category.id === settings.mainWok 
                                ?
                                { ...item, isWok: true }
                                :
                                item
                            )
                        );
            }
            else
            {
                setGoods(goodsChek.filter((item) => item.category.id === activeCategory || item.category.id === settings.mainWok)
                    .sort((a, b) => {
                        if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                        if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                        return 0;
                    })
                        .sort((a, b) => b.category.id - a.category.id)
                        .map((item) => {
                            return { ...item, isWok: true }
                        })
                        .filter((item) => { //проверка на доступность позиции в городе
                            const disabledCityIds = item.disabledCities ? item.disabledCities.split(',') : [];
                            return !disabledCityIds.some((id) => parseInt(id) === point.city.id);
                        })
                        .map((item) => //проверка региональных цен
                            item.regionprices.find((regionprices) => 
                                regionprices.cities.some((city) => parseInt(city.id) === point.city.id))
                            ?
                            { ...item, 
                                price: item.price + item.regionprices.find((regionprices) => 
                                    regionprices.cities.some((city) => parseInt(city.id) === point.city.id)).price, 
                                oldPrice: item.oldPrice + item.regionprices.find((regionprices) => 
                                    regionprices.cities.some((city) => parseInt(city.id) === point.city.id)).oldPrice
                            } 
                            : 
                            item                   
                        )
                    );
            }

        if(settings && activeCategory === settings.pizza)        
            if(!point)
            {
                setGoods(goodsChek.filter((item) => item.category.id === activeCategory)
                        .sort((a, b) => {
                            if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                            if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                            return 0;
                        })
                        .map((item) => {
                            return { ...item, isMod: item.modifications.find((el) => el.default)}
                        })
                );
            }
            else
            {
                setGoods(goodsChek.filter((item) => item.category.id === activeCategory)
                        .sort((a, b) => {
                            if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                            if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                            return 0;
                        })
                        .map((item) => {
                            return { ...item, isMod: item.modifications.find((el) => el.default)}
                        })
                        .filter((item) => { //проверка на доступность позиции в городе
                            const disabledCityIds = item.disabledCities ? item.disabledCities.split(',') : [];
                            return !disabledCityIds.some((id) => parseInt(id) === point.city.id);
                        })
                        .map((item) => //проверка региональных цен
                            item.regionprices.find((regionprices) => 
                                regionprices.cities.some((city) => parseInt(city.id) === point.city.id))
                            ?
                            { ...item, 
                                price: item.price + item.regionprices.find((regionprices) => 
                                    regionprices.cities.some((city) => parseInt(city.id) === point.city.id)).price, 
                                oldPrice: item.oldPrice + item.regionprices.find((regionprices) => 
                                    regionprices.cities.some((city) => parseInt(city.id) === point.city.id)).oldPrice
                            } 
                            : 
                            item                   
                        )
                );
            }
    }, [activeCategory, goodsChek, settings, point]);

    useEffect(() => {
        filterGoods();
    }, [filterGoods]);

    useEffect(() => {
        if(searchGoods && goodsChek)
            setSearchingGoods(goodsChek.filter(good => good.name.toLowerCase().includes(searchGoods.toLowerCase()))
            .filter((item) => { //проверка на доступность позиции в городе
                const disabledCityIds = item.disabledCities ? item.disabledCities.split(',') : [];
                return !disabledCityIds.some((id) => parseInt(id) === point.city.id);
            }).slice(0, 5)
            .map((item) => //проверка региональных цен
                item.regionprices.find((regionprices) => 
                    regionprices.cities.some((city) => parseInt(city.id) === point.city.id))
                ?
                { ...item, 
                    price: item.price + item.regionprices.find((regionprices) => 
                        regionprices.cities.some((city) => parseInt(city.id) === point.city.id)).price, 
                    oldPrice: item.oldPrice + item.regionprices.find((regionprices) => 
                        regionprices.cities.some((city) => parseInt(city.id) === point.city.id)).oldPrice
                } 
                : 
                item                   
            ))
        if(!searchGoods)
            setSearchingGoods(null)
    }, [searchGoods, goodsChek, point])
    useEffect(() => ReactTooltip.rebuild(), [goods, loadingGoods]);
    
    useEffect(() => {        
        dispatch(clearNewOrder())
        isEmpty(settings) && dispatch(fetchSettings());
        isEmpty(goodsChek) && dispatch(fetchGoods());
        isEmpty(categories) && dispatch(fetchCategories());
        dispatch(fetchFilials(null, true));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const renderContent = () => {
        if(loadingGoods || loadingCategories || loadingFilials)
            return(
                <div className='kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid'>
                    <div style={styles.tableLoading}><Loader text='Загружаю данные'/></div>
                </div>
            )

        if(loadingSaveOrder)
            return(
                <div className='kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid'>
                    <div style={styles.tableLoading}><Loader text='Сохраняю заказ'/></div>
                </div>
            )

        return(
            <div className='kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid'>
                <div className="kt-grid kt-grid--desktop kt-grid--ver-desktop kt-todo">
                    <div className="neworder_container order_position_list">
                        <OrderPositionList/>
                    </div>
                    <div className="neworder_container detail" >
                        <DetailNewOrder/>
                    </div>
                    <div className="neworder_container categories">
                       <Categories categories={categories} activeCategory={activeCategory} onSelectCategory={onSelectCategory}/>
                    </div>
                    <div className="neworder_good_main">
                        <div className="neworder_good_head">                            
                            <div style={{display: "flex", gap: '10px', alignItems: 'center', marginTop: '10px'}}>
                                <label>Цены агрегаторов</label>
                                <div>
                                    <span className="kt-switch kt-switch--sm kt-switch--icon">
                                    <label>
                                        <input 
                                        type="checkbox" 
                                        checked={isAggPrice} 
                                        onChange={(e) => dispatch(changeIsAggPriceNewOrder(e.target.checked))} 
                                        />
                                        <span className="new_order_switch"></span>
                                    </label>
                                    </span>
                                </div>
                            </div>
                            <div>
                                {point && goodsChek &&
                                    <>
                                        <div>
                                        <input 
                                            type="text"
                                            className="form-control search"
                                            autocomplete="false"
                                            placeholder="Поиск"
                                            value={searchGoods}
                                            onChange={((e) => setSearchGoods(e.target.value))}
                                            onBlur={(() => setSearchGoods(''))}                                            
                                        />
                                        {searchGoods && searchingGoods && searchingGoods.length > 0 &&
                                            <div className="new_order_search_div">
                                                <div className="new_order_search_container">
                                                    {searchingGoods.map((item) => 
                                                        <button
                                                        className={`new_order_search_button ${point.stopList.some((el) => el.value === item.id) ? 'unactived' : ''}`}
                                                        onMouseDown={((e) => e.preventDefault())}
                                                        onClick={(() => {
                                                            if(!point.stopList.some((el) => el.value === item.id))
                                                            {
                                                                dispatch(addPositionNewOrder(item, settings));
                                                                setSearchGoods('');
                                                            }
                                                        })}
                                                        >
                                                            <span className="neworder_good_button_text" >{item.name}</span>
                                                            {!isAggPrice 
                                                            ?
                                                            <span className="neworder_good_button_text" style={{ fontWeight: '600', fontSize: '0.9rem' }}> {`${item.isMod ? item.price + item.isMod.price : item.price} ₸`} </span>
                                                            :
                                                            <span className="neworder_good_button_text" style={{ fontWeight: '600', fontSize: '0.9rem' }}> {`${item.isMod ? item.oldPrice + item.isMod.oldPrice : item.oldPrice} ₸`} </span>
                                                            }
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        }
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="neworder_container goods">
                            <Goods loadingGoods={loadingGoods} goods={goods}changeModification={changeModification}/>
                            <ReactTooltip
                                effect="solid"
                                type="dark"
                                place="top"
                            />       
                        </div>  
                    </div>    
                </div>
            </div>
        )
    }
  
    return (
        <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">                
                {renderContent()}                                                    
            </div>
        </div>
    );
}
export default NewOrder;